export const FILES = {
  FILES: 'file/api/file',

  FILE(id) {
    return `${this.FILES}/${id}`
  },

  // /file/api/file/upload
  get UPLOAD() {
    return `${this.FILES}/upload`
  },

  // /file/api/file/update
  get UPDATE() {
    return `${this.FILES}/update`
  },

  // /file/api/file/allfilesforuser
  get ALL_FILES_FOR_USER() {
    return `${this.FILES}/allfilesforuser`
  },

  // /file/api/file/fileinfo/${id}
  FILE_INFO(id) {
    return `${this.FILES}/fileinfo/${id}`
  },

  // /file/api/file/categories
  get CATEGORIES() {
    return `${this.FILES}/categories`
  },

  // /file/api/file/updateCategoryTitle
  get UPDATE_CATEGORY_TITLE() {
    return `${this.FILES}/updateCategoryTitle`
  },

  // /file/api/file/filelisttypetypeid
  get FILE_LIST_TYPE_TYPE_ID() {
    return `${this.FILES}/filelisttypetypeid`
  },

  // /file/api/file/fileListCount
  get FILE_LIST_COUNT() {
    return `${this.FILES}/fileListCount`
  },

  // /file/api/file/accountlogo
  get ACCOUNT_LOGO() {
    return `${this.FILES}/accountlogo`
  },
}
